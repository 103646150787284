import React from 'react';
import Dashnav from '../../../components/dashnav/dashnav';
import Footer from '../../../components/footer/footer';
import { Redirect } from 'react-router';
import {Helmet} from "react-helmet";
import axios from "axios";

class Sales2024 extends React.Component {
  
constructor(props) {
  super(props);
  this.checkLogin = this.checkLogin.bind(this);
  this.state = {
    username: '',
    password: '',
    data:'',
    error: '',
    login: '',
    redirect: '',
  }
}

componentDidMount() {

  window.addEventListener('load', this.checkLogin);

}
  
  componentWillUnmount() { 

    window.addEventListener('load', this.checkLogin);
  }

  getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

checkLogin = () =>{
    if(this.getCookie('username') && this.getCookie('loggedin')){
        this.setState({login: true});
        this.setState({redirect: false});
        this.setState({username: this.getCookie('username')});
        console.log('Already loggedin');
        
  var date = new Date();
  var dateInput = (date.getMonth() + 1) + "-" + date.getDate() + "-" + date.getFullYear();
  var timeInput = date.toLocaleTimeString();

    var bodyFormData = new FormData();
    bodyFormData.append('username', this.getCookie('username'));
    bodyFormData.append('page', "Sales2024");
    bodyFormData.append('action', "access");
    bodyFormData.append('date', dateInput);
    bodyFormData.append('time', timeInput);

    axios({
      method: "post",
      url: "http://174.32.189.27:8080/api/access/insert.php",
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        //handle success
        console.log(response);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
    }
    else{
      console.log('Already loggedout.');
      this.setState({redirect: true});
    }
  }

  render() {

    return(
      <div className='container animate-bottom' style={{maxWidth: '100%'}}>
        <Helmet>
                <title>Case Medical, Inc. | Viewbox - PipelineSales</title>
            </Helmet>
          {this.state.redirect === true &&
              <Redirect to="/" />
              }
        <Dashnav></Dashnav>
            <div>
            <iframe title="Pipeline Sales Analysis 2024" width="100%" height="800" src="https://app.powerbi.com/view?r=eyJrIjoiM2YwNzZmM2QtNTE0Mi00MTZjLTgwYjQtOTU3ZDk1NDMyZTViIiwidCI6IjMyYzAwNDIxLTRkMmEtNDkzOS05YWE3LTliZGVjMmUyNzIwNyIsImMiOjF9" frameborder="0" allowFullScreen="true"></iframe>
           </div>
           <div style={{textAlign: 'center'}}>
        <Footer></Footer>
        </div>
        </div>
    );
  }
}

export default Sales2024;
